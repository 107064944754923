import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import SEO from '../components/seo'
import NewsSectionBlock from '../components/NewsSectionBlock'
import Typography from '../components/Common/Typography'
import List from '../components/Common/List'
import NewsQuoteBlock from '../components/NewsQuoteBlock'

import * as s from '../pages/news.module.scss'

const BimManagementAutomation = () => {
  const images = useStaticQuery(imagesQuery)

  return (
    <>
      <SEO
        title="BIM management automation software"
        description="Checker is a comprehensive solution for BIM management automation. From clash detection to presence of properties checks, this feature ensures the highest accuracy and  compliance with EIR in your BIM models"
      />
      <NewsSectionBlock date="July 09, 2024">
        <Typography variant="h1" color="blue">
          {`SIGNAX TOOLS: Checker – Automated BIM manager for\u00A0your\u00A0company`}
        </Typography>
        <Typography variant="h2" size={24} mb={16}>
          About the author
        </Typography>
        <div className="flex items-start">
          <StaticImage
            width={50}
            src="../assets/images/author/Paul.png"
            alt="Pavel Goretskiy"
            title="Pavel Goretskiy"
            placeholder="blurred"
          />
          <div className="w-100 ml-4">
            <Typography variant="body1" className="m-0">
              Pavel Goretskiy — BIM manager in SIGNAX, responsible for
              overseeing on-site BIM implementation, managing the client’s
              learning program, and implementing SIGNAX solutions.
            </Typography>
          </div>
        </div>
        <Typography variant="h2" size={24} mb={16}>
          Introduction
        </Typography>
        <Typography variant="body1">
          The continuous development and growing adoption of the BIM technology
          led to an increasing interest in model checking tools and to the
          development of a new generation of software for Quality Assurance and
          Quality Control based on parametric rules. Today model checking is an
          integral and essential component of Information Modelling and
          Management.
        </Typography>
        <Typography variant="body1">
          SIGNAX successfully integrated its solutions for a Basalt Fiber
          factory construction project, demonstrating its expertise. One of the
          key products integrated was the Checker within the SIGNAX TOOLS
          module. The team closely consulted with the EPC contractor team:
          designers, project managers, and engineers, including Eng. Mahmoud
          El-Khatib, Senior MEP & Infrastructure VDC Manager, who provided a
          detailed review of the product and shared the positive results of its
          implementation. SIGNAX was not just a vendor in this project, but also
          an SME that excels in seamless software implementation.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../assets/images/solutions/bim-management-automation/image-1.png"
            alt="Basalt fiber factory federated model"
            title="Basalt fiber factory federated model"
            placeholder="blurred"
          />
          <Typography variant="body2" color="gray">
            Basalt fiber factory federated model
          </Typography>
        </div>
        <Typography variant="h2" size={24} mb={16}>
          Challenge
        </Typography>
        <Typography variant="body1">
          SIGNAX technologies facilitate the implementation of digital projects
          in the construction industry. However, a critical requirement for
          effectively utilizing data on construction sites is ensuring its
          accuracy, completeness, and quality, all of which stem from the design
          phase. Therefore, it is essential to prioritize the quality of project
          data and models. This responsibility can lie with the design
          organization, which must ensure data quality before transferring it to
          the customer, or with the customer, who may seek to automate the
          verification process to ensure compliance with their information
          requirements (EIR).
        </Typography>
        <Typography variant="body1">
          A VDC manager in a company is a highly multitasking professional who
          must excel in various areas. They need to communicate and manage data
          effectively, identify potential project issues, and coordinate actions
          between different teams and departments. Additionally, they must stay
          within budget, comply with regulatory requirements and standards,
          ensure the accuracy of project data, and keep up with industry
          development pace.
        </Typography>
        <Typography variant="body1">
          Some of these tasks, such as project data verification, can be
          automated or simplified. Verifying BIM models manually is challenging
          due to the vast amount of information they contain and the constant
          changes within the models. It is also impractical to track these
          changes manually.
        </Typography>
        <Typography variant="h2" size={24} mb={16}>
          Solution
        </Typography>
        <Typography variant="body1">
          To address this, we developed the Checker feature within SIGNAX TOOLS.
          Checker is a comprehensive solution for checking and verifying BIM
          models based on specified rules. It allows you to check the model for:
        </Typography>
        <List>
          <li>Clash detection</li>
          <li>Presence of properties</li>
          <li>Presence of properties for groups of elements</li>
          <li>Presence of at least one property</li>
          <li>Completeness of property values for groups of elements</li>
          <li>Presence of elements</li>
        </List>
        <Typography variant="body1">
          The Checker tool features an intuitive interface for creating various
          checks in BIM models, enabling efficient control and monitoring of
          compliance with informational requirements and the quality of the BIM
          model. Checker operates within Navisworks and Revit, facilitating the
          exchange of check results between these programs and other project
          participants. Additionally, it allows users to save and share check
          templates with other designers, BIM coordinators, or managers,
          enhancing collaboration and consistency across the project.
        </Typography>
        <Typography variant="h2" size={24} mb={16}>
          Checker options
        </Typography>
        <Typography variant="h3" size={18} className="m-0">
          Presence of properties
        </Typography>
        <Typography variant="body1">
          This rule checks that all specified properties of all model elements
          have values. Suitable for checking properties that must be filled in
          for all model elements. For example, check that the property "Assembly
          Code" is filled in for all elements.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../assets/images/solutions/bim-management-automation/image-2.png"
            alt='Presence of "Assembly Code" check for all model elements'
            title='Presence of "Assembly Code" check for all model elements'
            placeholder="blurred"
          />
          <Typography variant="body2" color="gray">
            Presence of "Assembly Code" check for all model elements
          </Typography>
        </div>
        <Typography variant="body1">
          Errors found during the verification process are displayed in the
          "Check Results" area. From there, you can navigate directly to the
          model element with the error and add comments. Verification results
          can be exported to Excel or XML. For instance, an XML file can be
          imported into Revit to quickly locate and fix elements with errors.
          The comparison function allows you to compare the current verification
          with previous ones to see which comments have been addressed.
          Importantly, all checks can be initiated with just a couple of clicks
          or by using pre-prepared templates, making the process efficient and
          user-friendly.
        </Typography>
        <NewsQuoteBlock
          avatar={images.avatar.childImageSharp.gatsbyImageData}
          name="Mahmoud El-khatib"
          position="Senior MEP & Infrastructure VDC Manager"
          text="“One of the main requirements for the informational content of MEP systems is the presence of System Name and Classification properties. Presence of these properties and many more was checked using SIGNAX Checker. This requirement is crucial not only for easy navigation within the model but also for the convenient creation of equipment/material specifications, additional documentation sheets, and various other practical tasks.”"
        />
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../assets/images/solutions/bim-management-automation/image-3.png"
            alt="Displaying MEP systems elements with various properties in the System Name"
            title="Displaying MEP systems elements with various properties in the System Name"
            placeholder="blurred"
          />
          <Typography variant="body2" color="gray">
            Displaying MEP systems elements with various properties in the
            System Name
          </Typography>
        </div>
        <Typography variant="h3" size={18} className="m-0">
          Presence of properties for groups of elements
        </Typography>
        <Typography variant="body1">
          This rule checks the completeness of properties for all model elements
          that contain a property with a specific value. For example, if the
          property "Category" has the value "Walls", then Checker verifies the
          presence of the properties "Building" and "Floor".
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../assets/images/solutions/bim-management-automation/image-4.png"
            alt='Presence of "Building" and "Floor" properties check for model elements with "Walls" category'
            title='Presence of "Building" and "Floor" properties check for model elements with "Walls" category'
            placeholder="blurred"
          />
          <Typography variant="body2" color="gray">
            Presence of "Building" and "Floor" properties check for model
            elements with "Walls" category
          </Typography>
        </div>
        <Typography variant="body1">
          Creating rules for this check is more convenient in an Excel
          spreadsheet.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../assets/images/solutions/bim-management-automation/image-5.png"
            alt="Verification template example"
            title="Verification template example"
            placeholder="blurred"
          />
          <Typography variant="body2" color="gray">
            Verification template example
          </Typography>
        </div>
        <NewsQuoteBlock
          avatar={images.avatar.childImageSharp.gatsbyImageData}
          name="Mahmoud El-khatib"
          position="Senior MEP & Infrastructure VDC Manager"
          text="“There are strict requirements for presence of properties for BIM models especially in MEP disciplines. We perform regular model checks, using SIGNAX Checker to control BIM models quality for every revision. One of the key tasks is to conduct calculations within the BIM model. For instance, all elements within the compressed air system must have flow rate properties, and compressors must include performance properties. This allows for the use of additional tools to verify the compressed air flow rate under various scenarios, ensuring accurate and efficient system analysis.”"
        />
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../assets/images/solutions/bim-management-automation/image-6.png"
            alt="Compressed air system room"
            title="Compressed air system room"
            placeholder="blurred"
          />
          <Typography variant="body2" color="gray">
            Compressed air system room
          </Typography>
        </div>
        <Typography variant="h3" size={18} className="m-0">
          Presence of one of the properties
        </Typography>
        <Typography variant="body1">
          This rule checks the completeness of the property values based on
          specified variants for each property. For instance, if a property like
          "Volume" can appear under different names such as "Volume", "Vol.", or
          "Dynamo_Volume", etc. The tool verifies if at least one of these
          variants exists and has value.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../assets/images/solutions/bim-management-automation/image-7.png"
            alt="Presence of “Volume” property in elements of the model"
            title="Presence of “Volume” property in elements of the model"
            placeholder="blurred"
          />
          <Typography variant="body2" color="gray">
            Presence of “Volume” property in elements of the model
          </Typography>
        </div>
        <Typography variant="h3" size={18} className="m-0">
          Completeness of properties values for groups of elements
        </Typography>
        <Typography variant="body1">
          This function checks if all model elements that contain a specific
          property value have a required value assigned to a property. For
          example, if elements with a conditional property "Category" have the
          value "Walls", the verification property "Thickness" equals "0.020".
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../assets/images/solutions/bim-management-automation/image-8.png"
            alt='Check if "Width" has a certain value for elements with "Walls" Category'
            title='Check if "Width" has a certain value for elements with "Walls" Category'
            placeholder="blurred"
          />
          <Typography variant="body2" color="gray">
            Check if "Width" has a certain value for elements with "Walls"
            Category
          </Typography>
        </div>
        <Typography variant="h3" size={18} className="m-0">
          Clash detective
        </Typography>
        <Typography variant="body1">
          This function checks for clashes between created search sets, allowing
          you to identify conflicts between elements within these sets. Check
          elements for clashes with other elements or for self-clashes within
          the same search set. Create search sets comprising elements that need
          to be checked for clashes to effectively manage coordination issues in
          your project.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../assets/images/solutions/bim-management-automation/image-9.png"
            alt="Clash detective rules and results"
            title="Clash detective rules and results"
            placeholder="blurred"
          />
          <Typography variant="body2" color="gray">
            Clash detective rules and results
          </Typography>
        </div>
        <NewsQuoteBlock
          avatar={images.avatar.childImageSharp.gatsbyImageData}
          name="Mahmoud El-khatib"
          position="Senior MEP & Infrastructure VDC Manager"
          text="“When modifying the routing of MEP systems, such as HVAC ducts, it is essential to perform clash detection. Clashes should be checked not only with other MEP systems but also with structural elements and within the system itself. This proactive approach saves time on subsequent corrections and model coordination. For instance, during the clash detection of the latest HVAC model revision, intersections with steel structure elements were identified. This allowed for the preventive correction of design errors, reducing the time needed for on-site approval and adjustments.”"
        />
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../assets/images/solutions/bim-management-automation/image-10.png"
            alt="HVAC and Metal structures collisions"
            title="HVAC and Metal structures collisions"
            placeholder="blurred"
          />
          <Typography variant="body2" color="gray">
            HVAC and Metal structures collisions
          </Typography>
        </div>
        <Typography variant="h3" size={18} className="m-0">
          Presence of items
        </Typography>
        <Typography variant="body1">
          Check the presence of elements in the model based on specified
          properties. For example, check if the model contains 50 windows.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../assets/images/solutions/bim-management-automation/image-11.png"
            alt='Presence of 50 elements with "Windows" category'
            title='Presence of 50 elements with "Windows" category'
            placeholder="blurred"
          />
          <Typography variant="body2" color="gray">
            Presence of 50 elements with "Windows" category
          </Typography>
        </div>
        <NewsQuoteBlock
          avatar={images.avatar.childImageSharp.gatsbyImageData}
          name="Mahmoud El-khatib"
          position="Senior MEP & Infrastructure VDC Manager"
          text="“Throughout the design stage, we conducted weekly checks across all project disciplines using SIGNAX TOOLS. We continue to perform these checks to account for as-built changes in MEP systems, significantly reducing the resources needed for rework and the time required for further coordination.”"
        />
        <Typography variant="h2" size={24} mb={16}>
          Conclusion
        </Typography>
        <Typography variant="body1">
          The Checker tool allows for a quick review of the model according to
          EIR requirements for clashes, or completeness of information.
          Moreover, when working with large models or assemblies, Checker can
          perform checks in background, automated mode without requiring the
          specialist’s involvement, allowing other parallel tasks to be
          addressed simultaneously. Checker enables seamless and effective
          multidisciplinary collaboration and always meets client’s
          requirements.
        </Typography>
      </NewsSectionBlock>
    </>
  )
}

export default BimManagementAutomation

const imagesQuery = graphql`
  query {
    avatar: file(relativePath: { eq: "author/Mahmoud_El-khatib.png" }) {
      childImageSharp {
        gatsbyImageData(width: 50, placeholder: BLURRED)
      }
    }
  }
`
